import { FunctionComponent, MouseEventHandler, useCallback } from 'react';
import { LoginAsDifferentUser } from '../../Types/StepProps';
import styles from './styles.module.scss';

export type Props = {
    onClick: LoginAsDifferentUser;
};
const LoginAsDifferentUserButton: FunctionComponent<Props> = ({ onClick }) => {
    const onButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.preventDefault();
            onClick();
        },
        [onClick]
    );

    return (
        <button className={styles.button} onClick={onButtonClick}>
            Log in as a Different User
        </button>
    );
};

export default LoginAsDifferentUserButton;
