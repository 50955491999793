import { FunctionComponent } from 'react';
import Alert from '../../../../components/Alert';
import styles from './styles.module.scss';

export type Props = {
    message?: string;
};

const Message: FunctionComponent<Props> = ({ message }) => {
    if (!message) {
        return null;
    }

    return (
        <Alert className={styles.message} preset="info">
            {message}
        </Alert>
    );
};

export default Message;
