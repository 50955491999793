import { AwSnap, CademyError } from '@shared/domain-shared';

export type Middleware = (response: any) => void;

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export type AuthResponse<Body = any> = {
    statusCode: number;
    body: Body;
    headers: Record<string, string>;
};

class AuthRequest {
    private readonly domain = process.env.NEXT_PUBLIC_CADEMY_API_URL;

    constructor(private readonly middleware: Middleware) {
        //
    }

    async get<ResponseBody = any>(
        path: string,
        params: Record<string, any>,
        headers: Record<string, any>
    ): Promise<AuthResponse<ResponseBody>> {
        const urlParams = params ? '?' + new URLSearchParams(params).toString() : '';
        const result = await fetch(`${this.domain}/${path}${urlParams}`, {
            method: 'GET',
            headers: Object.assign({}, defaultHeaders, headers),
            credentials: 'include',
        });

        const response = await this.makeResponse(result);

        this.middleware(response);

        return response;
    }

    async post<ResponseBody = any>(
        path: string,
        params: Record<string, any>,
        headers: Record<string, any>
    ): Promise<AuthResponse<ResponseBody>> {
        const body = JSON.stringify(params);

        const result = await fetch(`${this.domain}/${path}`, {
            method: 'POST',
            body: body,
            headers: Object.assign({}, defaultHeaders, headers),
            credentials: 'include',
        });

        const response = await this.makeResponse(result);

        this.middleware(response);

        return response;
    }

    private async makeResponse<ResponseBody = any>(
        response: Response
    ): Promise<AuthResponse<ResponseBody>> {
        if (response.ok === false) {
            if (response.headers.get('content-type') === 'application/problem+json') {
                const problemDetails = await response.json();
                const error = CademyError.fromObject(problemDetails);
                throw error;
            }

            if (response.headers.get('content-type') === 'application/json') {
                const errorDetails = await response.json();
                throw new AwSnap(errorDetails.title ?? errorDetails.safe);
            }

            throw new AwSnap();
        }

        const responseBody = await response.json();

        return {
            statusCode: response.status,
            body: responseBody,
            headers: Object.fromEntries(response.headers.entries()),
        };
    }
}

export default AuthRequest;
