import { ReactNode, forwardRef } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const PRESET_CLASSES = {
    info: styles.info,
    warning: styles.warning,
    danger: styles.danger,
    success: styles.success,
} as const;

export type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
    preset: keyof typeof PRESET_CLASSES;
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
    ({ children, preset, className, ...divProps }, ref) => {
        const presetClass = PRESET_CLASSES[preset || 'info'];
        return (
            <div
                role="alert"
                aria-live="polite"
                className={classnames(styles.alert, presetClass, className)}
                {...divProps}
                ref={ref}
            >
                {children}
            </div>
        );
    }
);
Alert.displayName = 'Alert';

export default Alert;
