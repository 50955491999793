import { FunctionComponent, PropsWithChildren } from 'react';
import LoginAsDifferentUserButton from '../LoginAsDifferentUserButton';
import { LoginAsDifferentUser } from '../../Types/StepProps';
import styles from './styles.module.scss';

type Props = {
    loginAsDifferentUser: LoginAsDifferentUser;
};

const StepWithEscape: FunctionComponent<PropsWithChildren<Props>> = ({
    children,
    loginAsDifferentUser,
}) => {
    return (
        <div className={styles.container}>
            {children}

            <div className={styles.button}>
                <LoginAsDifferentUserButton onClick={loginAsDifferentUser} />
            </div>
        </div>
    );
};

export default StepWithEscape;
