import { FunctionComponent } from 'react';
import Spinner from '../../../../components/Spinner';
import styles from './styles.module.scss';
import StepDefinitionType from '../../Types/StepDefinition';
import Card from '../../../../components/Card';
import StepProps from '../../Types/StepProps';

const Holding: FunctionComponent<StepProps> = () => {
    return (
        <Card className={styles.card} padded={false}>
            <div className={styles.container}>
                <div className={styles.inner}>
                    <Spinner innerClassName={styles.spinner} />
                </div>
            </div>
        </Card>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === undefined) {
            return true;
        }
        return false;
    },
    StepComponent: Holding,
};

export default Holding;
