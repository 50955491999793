export const exponentialBackoffRetry = async <TCallback extends (...args: any) => any>(
    callback: TCallback,
    options: { maximumTries: number; exponent?: number; initialBackoffDelay?: number }
): Promise<ReturnType<TCallback>> => {
    const { maximumTries, exponent = 1.5, initialBackoffDelay = 250 } = options;
    try {
        return await callback();
    } catch (error) {
        if (maximumTries === 1) {
            throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, initialBackoffDelay));
        const nextDelay = initialBackoffDelay + initialBackoffDelay * (exponent - 1);
        return exponentialBackoffRetry(callback, {
            maximumTries: maximumTries - 1,
            exponent,
            initialBackoffDelay: nextDelay,
        });
    }
};

export default exponentialBackoffRetry;
