import {
    ChangeEventHandler,
    FormEventHandler,
    FunctionComponent,
    useCallback,
    useId,
    useMemo,
    useState,
} from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import LoginAsDifferentUserButton from '../../Components/LoginAsDifferentUserButton';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';

const Password: FunctionComponent<StepProps> = ({
    request,
    loginAsDifferentUser,
    loginValues,
    setError,
    ErrorAlert,
}) => {
    const id = useId();
    const [password, setPassword] = useState<string>('');
    const email = useMemo(() => {
        return loginValues?.challengeParameters?.email;
    }, [loginValues]);

    const canEnableNextButton = useMemo<boolean>(() => {
        if (!password || password.trim() === '') {
            return false;
        }

        return true;
    }, [password]);

    const loginWithPassword = useCallback<FormEventHandler>(
        (event) => {
            event.preventDefault();
            setError(undefined);

            return request
                .post('auth/login/with-password', { email, password: password.trim() }, {})
                .catch((err) => {
                    setError(err);
                });
        },
        [email, password, request, setError]
    );

    const forgotPassword = useCallback(() => {
        setError(undefined);

        return request.post('auth/user/forgot-password', { email }, {}).catch((err) => {
            setError(err);
        });
    }, [email, request, setError]);

    const loginMagically = useCallback(() => {
        setError(undefined);

        try {
            return request.post('auth/magic-login', { email }, {});
        } catch (err: any) {
            setError(err);
        }
    }, [email, request, setError]);

    const handlePasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setPassword(event.target.value);
    }, []);

    return (
        <>
            <Step title="Enter Your Password">
                <ErrorAlert />
                <form>
                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id={`${id}_email`}
                            type="email"
                            disabled
                            placeholder="Email"
                            value={email}
                        />
                        <label htmlFor={`${id}_email`}>Email</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id={`${id}_password`}
                            type="password"
                            placeholder="Password"
                            onChange={handlePasswordChange}
                            autoFocus
                        />
                        <label htmlFor={`${id}_password`}>Password</label>
                    </div>

                    <div className={styles.actionBar}>
                        <ButtonWithLoadingState
                            preset="primary"
                            type="submit"
                            onClick={loginWithPassword}
                            disabled={!canEnableNextButton}
                        >
                            Log in
                        </ButtonWithLoadingState>
                    </div>
                    <div className={styles.forgotPasswordBtns}>
                        <ButtonWithLoadingState
                            preset="default"
                            type="button"
                            onClick={forgotPassword}
                        >
                            Forgot Password
                        </ButtonWithLoadingState>
                        <ButtonWithLoadingState
                            preset="default"
                            type="button"
                            onClick={loginMagically}
                        >
                            Log in Without a Password
                        </ButtonWithLoadingState>
                    </div>
                </form>
            </Step>
            <div className={styles.underlinks}>
                <LoginAsDifferentUserButton onClick={loginAsDifferentUser} />
            </div>
        </>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'USER_PASSWORD') {
            return true;
        }

        return false;
    },
    StepComponent: Password,
};

export default Password;
