import classNames from 'classnames';
import { FunctionComponent, SVGProps } from 'react';
import styles from './styles.module.scss';

export type SpinnerProps = SVGProps<SVGSVGElement> & {
    outerClassName?: string;
    innerClassName?: string;
};

const Spinner: FunctionComponent<SpinnerProps> = ({ innerClassName, outerClassName, ...props }) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 150 150"
            className={classNames(styles.outer, outerClassName)}
            {...props}
        >
            <circle
                cx="75"
                cy="75"
                r="60"
                className={classNames(styles.inner, innerClassName)}
            ></circle>
        </svg>
    );
};

export default Spinner;
