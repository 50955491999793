import {
    ChangeEventHandler,
    FormEventHandler,
    FunctionComponent,
    useCallback,
    useId,
    useMemo,
    useState,
} from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import Message from '../../Components/Message';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';
import FacebookLoginIcon from './FacebookLoginIcon';
import GoogleLoginIcon from './GoogleLoginIcon';

const Email: FunctionComponent<StepProps> = ({ request, setError, ErrorAlert, loginValues }) => {
    const id = useId();
    const [email, setEmail] = useState<string>(loginValues.challengeParameters?.email ?? '');

    const canEnableNextButton = useMemo<boolean>(() => {
        if (!email || email.trim().length < 3) {
            return false;
        }

        if (!email.includes('@')) {
            return false;
        }

        return true;
    }, [email]);

    const submit = useCallback<FormEventHandler>(
        (event) => {
            event.preventDefault();

            setError(undefined);

            return request.post('auth/login', { email: email.trim() }, {}).catch((err) => {
                setError(err);
            });
        },
        [email, request, setError]
    );

    const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setEmail(event.target.value);
    }, []);

    const loginViaSocial = useCallback(
        (provider: 'Google' | 'Facebook') => {
            setError(undefined);

            return request.post('auth/login/via-social', { provider }, {}).catch((err) => {
                setError(err);
            });
        },
        [request, setError]
    );

    return (
        <Step title="Log in or Sign Up">
            <ErrorAlert />
            <div className={styles.message}>
                <Message message={loginValues.message} />
            </div>
            <form>
                <div className={sharedStyles.fieldContainer}>
                    <input
                        id={id}
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleEmailChange}
                        value={email}
                        autoFocus
                    />
                    <label htmlFor={id}>Email</label>
                </div>

                <div className={styles.actionBar}>
                    <ButtonWithLoadingState
                        type="submit"
                        preset="primary"
                        onClick={submit}
                        disabled={!canEnableNextButton}
                        className={styles.nextBtn}
                    >
                        Continue
                    </ButtonWithLoadingState>
                </div>
            </form>

            <div className={styles.otherProviderBtns}>
                <div className={styles.or}>or</div>
                <ButtonWithLoadingState
                    className={styles.googleBtn}
                    onClick={() => loginViaSocial('Google')}
                >
                    <div className={styles.iconWrapper}>
                        <GoogleLoginIcon />
                    </div>
                    <span className={styles.btnText}>Continue with Google</span>
                </ButtonWithLoadingState>
                <ButtonWithLoadingState
                    className={styles.facebookBtn}
                    onClick={() => loginViaSocial('Facebook')}
                >
                    <div className={styles.iconWrapper}>
                        <FacebookLoginIcon />
                    </div>
                    <span className={styles.btnText}>Continue with Facebook</span>
                </ButtonWithLoadingState>
            </div>
        </Step>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'EMAIL') {
            return true;
        }

        return false;
    },
    StepComponent: Email,
};

export default Email;
