import { ChangeEventHandler, FunctionComponent, useCallback, useMemo, useState } from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import StepWithEscape from '../../Components/StepWithEscape';
import { AwSnap } from '@shared/domain-shared';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';
import Message from '../../Components/Message';
import Image from 'next/image';

const SetNewPassword: FunctionComponent<StepProps> = ({
    request,
    loginAsDifferentUser,
    loginValues,
    setError,
    ErrorAlert,
}) => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

    const canEnableNextButton = useMemo<boolean>(() => {
        if (!newPassword || newPassword.length === 0) {
            return false;
        }

        if (!confirmNewPassword || newPassword.length === 0) {
            return false;
        }

        if (newPassword !== confirmNewPassword) {
            return false;
        }

        return true;
    }, [newPassword, confirmNewPassword]);

    const handleNewPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setNewPassword(event.target.value);
    }, []);

    const handleConfirmNewPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setConfirmNewPassword(event.target.value);
        },
        []
    );

    const setPassword = useCallback(() => {
        setError(undefined);

        if (!loginValues.context) {
            throw new AwSnap('Context Is Missing'); // TODO: Better error?
        }

        if (!loginValues.challengeParameters || !loginValues.challengeParameters.email) {
            throw new AwSnap('Email Is Missing'); // TODO: Better error?
        }

        const { sessionToken } = JSON.parse(loginValues.context);
        return request
            .post(
                'auth/user/set-password',
                {
                    email: loginValues.challengeParameters.email,
                    newPassword: newPassword.trim(),
                    confirmNewPassword: confirmNewPassword.trim(),
                    sessionToken: sessionToken,
                },
                {}
            )
            .catch((err) => {
                setError(err);
            });
    }, [setError, loginValues, request, newPassword, confirmNewPassword]);

    return (
        <StepWithEscape loginAsDifferentUser={loginAsDifferentUser}>
            <Step title="Set a New Password">
                <div className={sharedStyles.tagline}>
                    You must set a new password before you can continue.
                </div>

                <ErrorAlert />
                <Message message={loginValues.message} />

                <div className={sharedStyles.useRequired}>
                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="newPassword"
                            type="password"
                            required
                            placeholder="New Password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            autoFocus
                        />
                        <label htmlFor="newPassword">New Password</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="confirmNewPassword"
                            type="password"
                            required
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                        />
                        <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    </div>

                    <div className={styles.actionBar}>
                        <ButtonWithLoadingState
                            preset="primary"
                            onClick={setPassword}
                            disabled={!canEnableNextButton}
                        >
                            Set Password
                        </ButtonWithLoadingState>
                    </div>
                </div>
            </Step>
        </StepWithEscape>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'SET_NEW_PASSWORD') {
            return true;
        }

        return false;
    },
    StepComponent: SetNewPassword,
};

export default SetNewPassword;
