import {
    ChangeEventHandler,
    FormEventHandler,
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import StepWithEscape from '../../Components/StepWithEscape';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';

const SignUp: FunctionComponent<StepProps> = ({
    request,
    loginAsDifferentUser,
    loginValues,
    setError,
    ErrorAlert,
}) => {
    const initialEmailValue = useMemo<string>(() => {
        if (loginValues.challengeParameters?.email) {
            return loginValues.challengeParameters.email;
        }
        return '';
    }, [loginValues]);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>(initialEmailValue);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const canEnableNextButton = useMemo<boolean>(() => {
        if (firstName.length < 1) {
            return false;
        }

        if (lastName.length < 1) {
            return false;
        }

        if (email.length < 3) {
            return false;
        }

        return true;
    }, [firstName, lastName, email]);

    const handleFirstNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setFirstName(event.target.value);
    }, []);

    const handleLastNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setLastName(event.target.value);
    }, []);

    const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setEmail(event.target.value);
    }, []);

    const handlePhoneChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setPhoneNumber(event.target.value);
    }, []);

    const handlePasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setPassword(event.target.value);
    }, []);

    const signUp = useCallback<FormEventHandler>(
        (e) => {
            e.preventDefault();
            setError(undefined);

            return request
                .post(
                    'auth/users',
                    {
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        password: password ? password.trim() : undefined,
                    },
                    {}
                )
                .catch((err) => {
                    setError(err);
                });
        },
        [setError, request, firstName, lastName, email, phoneNumber, password]
    );

    return (
        <StepWithEscape loginAsDifferentUser={loginAsDifferentUser}>
            <Step title="Complete Sign Up">
                <ErrorAlert />

                <div className={sharedStyles.useRequired}>
                    <form>
                        <div className={sharedStyles.fieldContainer}>
                            <input
                                id="firstName"
                                type="text"
                                required
                                placeholder="First Name"
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                            <label htmlFor="firstName">First Name</label>
                        </div>

                        <div className={sharedStyles.fieldContainer}>
                            <input
                                id="lastName"
                                type="text"
                                required
                                placeholder="Last Name"
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                            <label htmlFor="lastName">Last Name</label>
                        </div>

                        <div className={sharedStyles.fieldContainer}>
                            <input
                                id="email"
                                type="email"
                                required
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <label htmlFor="email">Email</label>
                        </div>

                        <div className={sharedStyles.fieldContainer}>
                            <input
                                id="phoneNumber"
                                type="tel"
                                placeholder="Phone"
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                            />
                            <label htmlFor="phoneNumber">Phone</label>
                        </div>

                        <div className={styles.passwordSection}>
                            <div className={sharedStyles.fieldContainer}>
                                <input
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <label htmlFor="password">Create Password</label>
                                <div className={sharedStyles.description}>
                                    This is optional. You can set a password later in your account.
                                </div>
                            </div>
                        </div>

                        <div className={styles.actionBar}>
                            <ButtonWithLoadingState
                                type="submit"
                                preset="primary"
                                onClick={signUp}
                                disabled={!canEnableNextButton}
                            >
                                Continue
                            </ButtonWithLoadingState>
                        </div>
                    </form>
                </div>
            </Step>
        </StepWithEscape>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'SIGN_UP') {
            return true;
        }

        return false;
    },
    StepComponent: SignUp,
};

export default SignUp;
