import {
    ChangeEventHandler,
    FormEvent,
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import StepWithEscape from '../../Components/StepWithEscape';
import { AwSnap, CademyError } from '@shared/domain-shared';
import Message from '../../Components/Message';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';
import { RequestNewCodeButton } from './RequestNewCodeButton';

const ConfirmMagicLogin: FunctionComponent<StepProps> = ({
    request,
    loginAsDifferentUser,
    loginValues,
    setError,
    ErrorAlert,
}) => {
    const email = useMemo<string>(() => {
        if (!loginValues.challengeParameters?.email) {
            throw new AwSnap('Email Is Missing');
        }

        return loginValues.challengeParameters?.email;
    }, [loginValues]);
    const [magicPassword, setMagicPassword] = useState<string>('');

    const canEnableNextButton = useMemo<boolean>(() => {
        return Boolean(magicPassword) && magicPassword.trim().length !== 0;
    }, [magicPassword]);

    const handleMagicPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setMagicPassword(event.target.value);
    }, []);

    const loginWithMagic = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            setError(undefined);

            try {
                if (!loginValues.context) {
                    throw new AwSnap('Context Is Missing'); // TODO: Better error?
                }

                const { sessionToken } = JSON.parse(loginValues.context);
                await request.post(
                    'auth/magic-login/claim',
                    {
                        email,
                        code: magicPassword.trim(),
                        sessionToken,
                    },
                    {}
                );

                setMagicPassword('');
            } catch (err) {
                setError(CademyError.fromUnknown(err));
            }
        },
        [email, loginValues, magicPassword, request, setError]
    );

    return (
        <StepWithEscape loginAsDifferentUser={loginAsDifferentUser}>
            <Step title="Log in Without a Password">
                <div className={sharedStyles.tagline}>
                    Check your email for a one-time login code.
                </div>

                <Message message={loginValues.message} />
                <ErrorAlert />

                <form className={sharedStyles.useRequired} onSubmit={loginWithMagic}>
                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="email"
                            type="email"
                            required
                            placeholder="Email"
                            value={email}
                            disabled
                        />
                        <label htmlFor="email">Email</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="magicPassword"
                            type="text"
                            required
                            placeholder="Login Code"
                            value={magicPassword}
                            onChange={handleMagicPasswordChange}
                            autoFocus
                        />
                        <label htmlFor="magicPassword">Login Code</label>
                        <div className={sharedStyles.description}>
                            Didn&apos;t get the email? Check your spam folder and if it&apos;s not
                            in your inbox within 5 minutes{' '}
                            <RequestNewCodeButton
                                request={request}
                                email={email}
                                setError={setError}
                                setMagicPassword={setMagicPassword}
                            />
                        </div>
                    </div>

                    <div className={styles.actionBar}>
                        <ButtonWithLoadingState
                            preset="primary"
                            type="submit"
                            onClick={loginWithMagic}
                            disabled={!canEnableNextButton}
                        >
                            Log in
                        </ButtonWithLoadingState>
                    </div>
                </form>
            </Step>
        </StepWithEscape>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'CONFIRM_MAGIC_LOGIN') {
            return true;
        }

        return false;
    },
    StepComponent: ConfirmMagicLogin,
};

export default ConfirmMagicLogin;
