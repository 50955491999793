export const FacebookLoginIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{ display: 'block', height: '20px', width: '20px' }}
        >
            <path fill="#1877F2" d="M32 0v32H0V0z"></path>
            <path
                fill="#FFF"
                d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.63h4.06V32h5V20.62h3.73l.7-4.62z"
            ></path>
        </svg>
    );
};

export default FacebookLoginIcon;
