import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { StepDefinition as CompletePasswordReset } from '../../Steps/CompletePasswordReset';
import { StepDefinition as ConfirmMagicLogin } from '../../Steps/ConfirmMagicLogin';
import { StepDefinition as Email } from '../../Steps/Email';
import { StepDefinition as Holding } from '../../Steps/Holding';
import { StepDefinition as Password } from '../../Steps/Password';
import { StepDefinition as SetNewPassword } from '../../Steps/SetNewPassword';
import { StepDefinition as SignUp } from '../../Steps/SignUp';
import { StepDefinition as Sso } from '../../Steps/Sso';
import { ChallengeName, ChallengeNames } from '../../Types/ChallengeName';
import AuthRequest, { AuthResponse } from '../../Services/AuthRequest';
import StepProps, { LoginAsDifferentUser } from '../../Types/StepProps';
import LoginValues from '../../Types/LoginValues';
import SetError from '../../Types/SetError';
import { AuthenticatedIAm } from '@shared/types';
import waitForAuth from '../../Services/waitForAuth';
import { CademyError } from '@shared/domain-shared';
import { useIam, useSetIam } from '../../../../services/IAm/hooks';

export type OnAuthenticated = (iam: AuthenticatedIAm) => unknown;

const steps = [
    Email,
    Password,
    SignUp,
    CompletePasswordReset,
    ConfirmMagicLogin,
    SetNewPassword,
    Sso,
    Holding,
];

const useLogin = ({
    setError,
    onAuthenticated,
    initialMessage,
}: {
    setError: SetError;
    onAuthenticated?: OnAuthenticated;
    initialMessage?: string;
}) => {
    const [challengeName, setChallengeName] = useState<ChallengeName>(undefined);
    const [challengeParameters, setChallengeParameters] = useState<Record<string, any> | undefined>(
        undefined
    );
    const [message, setMessage] = useState<string | undefined>(initialMessage);
    const [context, setContext] = useState<string | undefined>(undefined);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const { iam, iamReady } = useIam();
    const setIam = useSetIam();

    const loginValues = useMemo<LoginValues>(() => {
        return {
            challengeName: challengeName,
            challengeParameters: challengeParameters,
            context: context,
            email: undefined,
            message: message,
            authenticated: authenticated,
        };
    }, [challengeName, challengeParameters, context, message, authenticated]);

    useEffect(() => {
        if (loginValues.authenticated === true && loginValues.challengeName === undefined) {
            waitForAuth()
                .then(async (iam) => {
                    if (onAuthenticated) {
                        await onAuthenticated(iam);
                    }
                    await setIam(iam);
                })
                .catch((error) => setError(CademyError.fromUnknown(error)));
        }
    }, [loginValues, onAuthenticated, setError, setIam]);

    useEffect(() => {
        if (iamReady === false) {
            return;
        }
        if (iam.authenticated === true) {
            setAuthenticated(true);
            setMessage(undefined);
            setContext(undefined);
            setChallengeName(undefined);
            setChallengeParameters(undefined);
        } else {
            setChallengeName('EMAIL');
        }
    }, [iam, iamReady]);

    const middleware = useCallback((response: AuthResponse) => {
        if (response.body.challengeName && ChallengeNames.includes(response.body.challengeName)) {
            setChallengeName(response.body.challengeName as ChallengeName);
            setChallengeParameters(response.body.challengeParameters);
            setMessage(response.body.message ?? undefined);
            setContext(response.body.context ?? undefined);
        }

        if (response.body.accessToken) {
            setAuthenticated(true);
            setMessage(undefined);
            setContext(undefined);
            setChallengeName(undefined);
            setChallengeParameters(undefined);
        }
    }, []);

    const authRequest = useMemo<AuthRequest>(() => {
        return new AuthRequest(middleware);
    }, [middleware]);

    const loginAsDifferentUser: LoginAsDifferentUser = useCallback(() => {
        setChallengeName('EMAIL');
        setChallengeParameters(undefined);
        setMessage(undefined);
        setContext(undefined);
        setError(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const step = useMemo<FunctionComponent<StepProps>>(() => {
        const match = steps.find((step) => {
            return step.shouldMatch(loginValues);
        });

        if (match) {
            return match.StepComponent;
        }

        return NoStepFound;
    }, [loginValues]);

    return {
        StepComponent: step,
        request: authRequest,
        loginAsDifferentUser,
        loginValues,
    };
};

const NoStepFound: FunctionComponent<any> = () => {
    return <div>No Step Found</div>;
};

export default useLogin;
