import { PropsWithChildren, forwardRef, useMemo } from 'react';
import Alert from '../Alert';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type ErrorCardProps = PropsWithChildren<{
    inError: boolean;
    title?: string;
    detail?: string;
    className?: string;
}>;

const ErrorAlert = forwardRef<HTMLDivElement, ErrorCardProps>(
    ({ inError, title, detail, className, children }, ref) => {
        const Title = useMemo(() => {
            if (title) {
                return (
                    <>
                        <b>{title}</b>
                    </>
                );
            }

            return null;
        }, [title]);

        const Detail = useMemo(() => {
            if (detail) {
                return detail;
            }

            return null;
        }, [detail]);

        if (!inError) {
            return null;
        }

        return (
            <Alert className={classNames(styles.errorCard, className)} preset="danger" ref={ref}>
                {Title}
                {Detail}
                {children}
            </Alert>
        );
    }
);
ErrorAlert.displayName = 'ErrorAlert';

export default ErrorAlert;
