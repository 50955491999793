import { AwSnap, CademyError } from '@shared/domain-shared';
import { useCallback, useMemo, useState } from 'react';

const useCademyError = () => {
    const [errorInstance, setErrorInstance] = useState<CademyError | undefined>(undefined);

    const setError = useCallback((err: Error | CademyError | undefined) => {
        if (!err) {
            return setErrorInstance(undefined);
        }

        if (err instanceof CademyError) {
            return setErrorInstance(err);
        }

        console.error(err);

        return setErrorInstance(new AwSnap('Unknown error'));
    }, []);

    const { type, title, detail, inError } = useMemo(() => {
        if (errorInstance) {
            return {
                type: errorInstance.type,
                title: errorInstance.title,
                detail: errorInstance.detail,
                inError: true,
            };
        }

        return {
            type: undefined,
            title: undefined,
            detail: undefined,
            inError: false,
        };
    }, [errorInstance]);

    return {
        inError,
        type,
        title,
        detail,
        setError: setError,
    };
};

export default useCademyError;
