import { ChangeEventHandler, FunctionComponent, useCallback, useMemo, useState } from 'react';
import StepDefinitionType from '../../Types/StepDefinition';
import sharedStyles from '../../shared.module.scss';
import styles from './styles.module.scss';
import Step from '../../Components/Step';
import StepProps from '../../Types/StepProps';
import StepWithEscape from '../../Components/StepWithEscape';
import { AwSnap } from '@shared/domain-shared';
import ButtonWithLoadingState from '../../../../components/ButtonWithLoadingState';
import Message from '../../Components/Message';
import Image from 'next/image';

const CompletePasswordReset: FunctionComponent<StepProps> = ({
    request,
    loginAsDifferentUser,
    loginValues,
    setError,
    ErrorAlert,
}) => {
    const email = useMemo<string>(() => {
        if (!loginValues.challengeParameters?.email) {
            throw new AwSnap('Email Is Missing');
        }

        return loginValues.challengeParameters?.email;
    }, [loginValues]);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [confirmationCode, setConfirmationCode] = useState<string>('');

    const canEnableNextButton = useMemo<boolean>(() => {
        if (!newPassword || newPassword.length === 0) {
            return false;
        }

        if (!confirmNewPassword || newPassword.length === 0) {
            return false;
        }

        if (!confirmationCode || confirmationCode.length === 0) {
            return false;
        }

        return true;
    }, [newPassword, confirmNewPassword, confirmationCode]);

    const handleNewPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setNewPassword(event.target.value);
    }, []);

    const handleConfirmNewPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setConfirmNewPassword(event.target.value);
        },
        []
    );

    const handleConfirmationCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setConfirmationCode(event.target.value);
        },
        []
    );

    const resetPassword = useCallback(() => {
        setError(undefined);

        return request
            .post(
                'auth/user/forgot-password/confirm',
                {
                    email,
                    password: newPassword.trim(),
                    confirmPassword: confirmNewPassword.trim(),
                    confirmationCode: confirmationCode.trim(),
                },
                {}
            )
            .catch((err) => {
                setError(err);
            });
    }, [email, newPassword, confirmNewPassword, confirmationCode]);

    return (
        <StepWithEscape loginAsDifferentUser={loginAsDifferentUser}>
            <Step title="Reset Password">
                <div className={sharedStyles.tagline}>
                    Check your email for a password reset code
                </div>

                <ErrorAlert />
                <Message message={loginValues.message} />

                <div className={sharedStyles.useRequired}>
                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="email"
                            type="email"
                            required
                            placeholder="Email"
                            value={email}
                            disabled
                        />
                        <label htmlFor="email">Email</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="newPassword"
                            type="password"
                            required
                            placeholder="New Password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            autoFocus
                        />
                        <label htmlFor="newPassword">New Password</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="confirmNewPassword"
                            type="password"
                            required
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                        />
                        <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    </div>

                    <div className={sharedStyles.fieldContainer}>
                        <input
                            id="confirmationCode"
                            type="text"
                            required
                            placeholder="Reset Code"
                            value={confirmationCode}
                            onChange={handleConfirmationCodeChange}
                        />
                        <label htmlFor="confirmationCode">Reset Code</label>
                    </div>

                    <div className={styles.actionBar}>
                        <ButtonWithLoadingState
                            preset="primary"
                            onClick={resetPassword}
                            disabled={!canEnableNextButton}
                        >
                            Reset Password
                        </ButtonWithLoadingState>
                    </div>
                </div>
            </Step>
        </StepWithEscape>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'COMPLETE_PASSWORD_RESET') {
            return true;
        }

        return false;
    },
    StepComponent: CompletePasswordReset,
};

export default CompletePasswordReset;
