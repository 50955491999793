export const ChallengeNames = [
    'COMPLETE_PASSWORD_RESET',
    'CONFIRM_MAGIC_LOGIN',
    'EMAIL',
    'SET_NEW_PASSWORD',
    'SIGN_UP',
    'SSO',
    'USER_PASSWORD',
] as const;

export type ChallengeName = (typeof ChallengeNames)[number] | undefined;

export default ChallengeName;
