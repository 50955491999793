import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import Card from '../../../../components/Card';
import classNames from 'classnames';

export type Props = {
    title: string;
    className?: string;
};

const Step: FunctionComponent<PropsWithChildren<Props>> = ({ children, title, className }) => {
    return (
        <div>
            <Card className={classNames(styles.card, className)} padded={false}>
                <div>
                    <div className={styles.head}>
                        <h1>{title}</h1>
                    </div>

                    <div className={styles.body}>{children}</div>
                </div>
            </Card>
        </div>
    );
};

export default Step;
