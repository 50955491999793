import { FunctionComponent, useCallback } from 'react';
import useCademyError from '../../hooks/useCademyError';
import useLogin from '../../pages_components/login/Hooks/useLogin';
import ErrorAlert from '../ErrorAlert';
import { AuthenticatedIAm } from '@shared/types';

export type LoginProps = {
    message?: string;
    onAuthenticated?: (iam: AuthenticatedIAm) => unknown;
};

export const Login: FunctionComponent<LoginProps> = ({ message, onAuthenticated }) => {
    const { inError, title, detail, setError } = useCademyError();

    const { StepComponent, request, loginAsDifferentUser, loginValues } = useLogin({
        setError,
        onAuthenticated,
        initialMessage: message || undefined,
    });

    const errorAlertInstance = useCallback(
        () => <ErrorAlert inError={inError} title={title} detail={detail} />,
        [inError, title, detail]
    );

    return (
        <StepComponent
            request={request}
            loginAsDifferentUser={loginAsDifferentUser}
            loginValues={loginValues}
            setError={setError}
            ErrorAlert={errorAlertInstance}
        />
    );
};
