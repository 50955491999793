import { FunctionComponent, MouseEvent, useCallback, useMemo, useState } from 'react';
import Button, { ButtonProps } from '../Button';
import Spinner from '../Spinner';
import styles from './styles.module.scss';
import classNames from 'classnames';

type ButtonWithLoadingStateProps = Omit<ButtonProps<'button'>, 'ref' | 'onClick'> & {
    onClick: (event: MouseEvent<HTMLButtonElement>) => any | Promise<any>;
};

const ButtonWithLoadingState: FunctionComponent<ButtonWithLoadingStateProps> = ({
    onClick,
    children,
    className,
    preset,
    ...props
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const inner = useMemo(() => {
        if (loading) {
            return (
                <>
                    <Spinner
                        className={styles.spinner}
                        innerClassName={classNames({
                            [styles.spinnerDark]: !preset || preset === 'default',
                        })}
                        width={15}
                    />
                    <span>{children}</span>
                </>
            );
        }

        return <>{children}</>;
    }, [children, loading, preset]);

    const onClickHandler = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            setLoading(true);

            return onClick(event).finally(() => {
                setLoading(false);
            });
        },
        [onClick]
    );

    return (
        <Button
            className={classNames(styles.nextButton, className)}
            onClick={onClickHandler}
            preset={preset}
            {...props}
        >
            {inner}
        </Button>
    );
};

export default ButtonWithLoadingState;
