import { CademyError } from '@shared/domain-shared';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import AuthRequest from '../../../Services/AuthRequest';
import styles from './styles.module.scss';

type RequestNewCodeButtonProps = {
    request: AuthRequest;
    setError: (error: CademyError | Error | undefined) => void;
    setMagicPassword: (magicPassword: string) => void;
    email: string;
};
export const RequestNewCodeButton: FunctionComponent<RequestNewCodeButtonProps> = ({
    request,
    email,
    setError,
    setMagicPassword,
}) => {
    const [requested, setRequested] = useState<boolean>(true);
    const [countdown, setCountdown] = useState<number>(60);
    const [loading, setLoading] = useState<boolean>(false);

    const disabled = useMemo(() => {
        return requested || loading;
    }, [requested, loading]);

    const requestNewMagicCode = useCallback(async () => {
        if (disabled) {
            return;
        }
        setError(undefined);
        setRequested(false);

        try {
            setLoading(true);
            const response = await request.post(
                'auth/magic-login',
                {
                    email,
                },
                {}
            );
            setMagicPassword('');
            setLoading(false);
            setCountdown(60);
            setRequested(true);
            return response;
        } catch (err) {
            setError(CademyError.fromUnknown(err));
            setLoading(false);
        }
    }, [disabled, email, request, setError, setMagicPassword]);

    useEffect(() => {
        if (requested) {
            const timer = setTimeout(() => {
                if (countdown === 0) {
                    setRequested(false);
                    return;
                }
                setCountdown(countdown - 1);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [countdown, requested]);

    return (
        <button
            type="button"
            className={styles.requestNewCodeButton}
            onClick={requestNewMagicCode}
            disabled={disabled}
        >
            request a new one{requested ? ` (${countdown}s)` : ''}
        </button>
    );
};
