import exponentialBackoffRetry from '../../../../services/exponentialBackoffRetry';
import { CademyError } from '@shared/domain-shared';
import { IAm } from '@shared/types';

export const waitForAuth = async () => {
    return await exponentialBackoffRetry(
        async () => {
            const response = await fetch(`${process.env.NEXT_PUBLIC_CADEMY_API_URL}/users/iam`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok !== true) {
                if (response.headers.get('content-type') === 'application/problem+json') {
                    const problemDetails = await response.json();
                    const error = CademyError.fromObject(problemDetails);
                    throw error;
                }
                const error = await response.text();
                throw new Error(error);
            }

            const result = (await response.json()) as IAm;
            if (result.authenticated !== true) {
                throw new CademyError(
                    'auth/not-authenticated',
                    'Not authenticated',
                    undefined,
                    500
                );
            }
            return result;
        },
        { maximumTries: 8, exponent: 1.5, initialBackoffDelay: 100 }
    );
};

export default waitForAuth;
