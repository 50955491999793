import classNames from 'classnames';
import styles from './styles.module.scss';
import { PolymorphicComponentProps } from '../../types/PolymorphicProps';

export type CardProps<TElement extends React.ElementType> = PolymorphicComponentProps<
    TElement,
    { padded?: boolean }
>;

export const Card = <TElement extends React.ElementType = 'div'>({
    as,
    padded = true,
    className,
    ...props
}: CardProps<TElement>) => {
    const Component = as || 'div';
    const withPadding = padded ? styles.paddedCard : undefined;
    const cardClasses = classNames(styles.card, withPadding, className);

    return <Component {...props} className={cardClasses} />;
};

export default Card;
